import React, { useState } from 'react';
import '../assets/sass/main.scss';
import { graphql, useStaticQuery } from 'gatsby';
import Carousel from '../components/Carousel';
import useFetchPost from '../http/post-request';
import trackClickAnalytics from '../helpers/trackAnalytics';

import Header from '../components/Header';
import Layout from '../components/Layout';

// import loadable from '@loadable/component';

// const News = loadable(() => import('../components/News'))
// const Product = loadable(() => import('../components/Product'))

const IndexPage = () => {
  const {
    antiTheft: {
      content: {
        title,
        description,
        products: [{ pictures_Alt, imgs }],
        seo: { titleSeo, description: descriptionSeo, keywords },
      },
    },
  } = useStaticQuery(pageQuery);
  return (
    <Layout
      titleSeo={titleSeo}
      descriptionSeo={descriptionSeo}
      keywords={keywords}
    >
      <Header></Header>

      <section id='wrapper'>
        <section id='four' className='wrapper alt style1 advantagesSection'>
          <div className='inner'>
            <h2 className=''>{title}</h2>
            <br />
            <blockquote>{description}</blockquote>
          </div>

          <div>
            <ProductItem
              imgs={imgs}
              pictures_Alt={pictures_Alt}
              title={title}
            ></ProductItem>
          </div>

          {/* <About></About> */}
          {/* <hr></hr> */}

          {/*< ul className="actions">
            <li>
              <a href="/#" className="button">
                Browse All
              </a>
            </li>
          </ul> */}
        </section>
        {/* <Product></Product> */}
        {/* <News></News> */}
      </section>
    </Layout>
  );
};

const ProductItem = (props) => {
  const [isProductoOpened, setIsProductoOpened] = useState('');
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [loading, setLoading] = useState('');
  const [formMessage, setformMessage] = useState('');
  const [phone, setPhone] = useState('');
  const [shareContact, setshareContact] = useState(false);

  const handleClickAsk = (e) => {
    e.preventDefault();
    if (!isProductoOpened) {
      trackClickAnalytics(
        'Pedir Catálogo',
        'Click',
        'Abrir formulário',
        props.index
      );
    }
    setIsProductoOpened(!isProductoOpened);
  };

  const sendemail = (fromEmail, fromName, product, phone, shareContact) => {
    setLoading(true);
    useFetchPost
      .call(this, 'https://emailservice.closerdev.com/api/sendemail', {
        mailTo: 'marketing@mline.pt',
        subject: 'Client contact',
        fromName: name,
        nome: fromName,
        email: fromEmail,
        phone,
        message: `<br/><br/>Viva! <br/> \n\n

        O Cliente ${fromEmail} (${fromName}) pediu o catálogo referente a ${product} e ${
          !!shareContact ? '' : '<b><u>não</u></b>'
        } deseja partilhar o contacto para acções de marketing futuras.\n\n<br/><br/>

        Cumprimentos`,
      })
      .then((res) => {
        setLoading(false);

        setformMessage('Mensagem enviada!');

        setTimeout(() => {
          setformMessage('');
          setIsProductoOpened(false);
          setEmail('');
          setName('');
        }, 5000);
      })
      .catch((err) => {
        setLoading(false);
        setformMessage('Aconteceu algo de errado...');
        console.log(err);
      });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    trackClickAnalytics(
      'Pedir Catálogo',
      'Click',
      'Enviar formulário',
      props.title
    );
    sendemail.call(this, email, name, props.title, phone, shareContact);
  };
  return (
    <section
      id={props.title}
      className={props.styles}
      style={{ minHeight: '800px' }}
    >
      <div className='inner'>
        <div
          className='w-100'
          style={{
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <span className='image image-product'>
            {/* <img src={props.imgs[0]} alt="" /> */}
            {/*  <Slider>{getAllImages(props.imgs)}</Slider> */}
            <Carousel alt={props.pictures_Alt}>{props.imgs}</Carousel>
            {/* slides={props.imgs} */}
          </span>
        </div>
        <div
          className='content'
          style={{
            display: 'flex',
            justifyContent: 'center',
            marginTop: '25px',
          }}
        >
          {/* <h2 className='major'>{'TITULO' || props.title}</h2> */}
          {/* <p>{props.description}</p> */}
          <a
            onClick={handleClickAsk}
            className={isProductoOpened ? 'special opened' : 'special'}
          >
            Pedir catálogo
          </a>
          <form
            className={isProductoOpened ? 'fadeIn' : 'fadeOut'}
            onSubmit={handleSubmit}
          >
            <div className='fields'>
              <div className='field'>
                <label htmlFor='name'>Nome</label>
                <input
                  required
                  onChange={(e) => setName(e.target.value)}
                  value={name}
                  type='text'
                  name='name'
                  id='name'
                />
              </div>
              <div className='field'>
                <label htmlFor='email'>Email</label>
                <input
                  required
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  type='email'
                  name='email'
                  id='email'
                />
              </div>
              <div className='field'>
                <label htmlFor='phone'>Contacto Telefónico</label>
                <input
                  required
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                  type='text'
                  name='phone'
                  id='phone'
                />
              </div>
              <div className='field'>
                <div className='col-6 col-12-small'>
                  <input
                    type='checkbox'
                    value={shareContact}
                    onChange={(e) => setshareContact(e.target.checked)}
                    id='demo-copy'
                    name='demo-copy'
                  />
                  <label htmlFor='demo-copy'>
                    Quero partilhar os meus dados para acções de marketing
                    futuras
                  </label>
                </div>
              </div>
            </div>
            <h3>
              <b>{formMessage && formMessage !== '' ? formMessage : ''}</b>
            </h3>
            {!loading ? (
              <ul className='actions'>
                <li>
                  <input type='submit' value='Enviar Mensagem' />
                </li>
              </ul>
            ) : (
              <div className='loader'>Loading...</div>
            )}
          </form>
        </div>
      </div>
    </section>
  );
};
export default IndexPage;

export const pageQuery = graphql`
  query {
    antiTheft: mongodbCloserDevCmsPages(
      mongodb_id: { eq: "616db800d065c078e1dc44b5" }
    ) {
      content {
        title
        description
        products {
          pictures_Alt
          imgs
        }
        seo {
          titleSeo
          description
          keywords
        }
      }
    }
  }
`;
